import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./Dashboard/DashboardPage";
import Support from "./pages/Support/Support";
import Withdrawal from "./pages/Withdrawal/Withdrawal";
import Report from "./pages/Reports/Report";
import Event from "./pages/Events/Events";
import QandA from "./pages/Q&A/Q&A";
import Statistics from "./pages/Statistics/Statistics";
import Coupon from "./pages/Coupon/Coupon_List";
import Convertion from "./pages/Convertions/Convertion_List";



const User_List = lazy(() => import("./pages/Users/User_List"));
const Sub_Admin = lazy(() => import("./pages/Subadmin/Sub_Admin"));
const User_Details = lazy(() => import("./pages/Users/User_Details"));
const User_Chat = lazy(() => import("./pages/Users/User_Chat"));
const Convertion_Chat = lazy(() => import("./pages/Convertions/Convertion_Chat"));




const Photo_Grapher = lazy(() =>
  import("./pages/Photo Graphers/Photo_Grapher")
);
const OnBoarding = lazy(() =>
  import("./pages/OnBoarding/OnBoarding")
);
const PhotoGrapher_Details = lazy(() =>
  import("./pages/Photo Graphers/PhotoGrapher_Details")
);
const Booking = lazy(() => import("./pages/Booking/Booking"));
const Skill_List = lazy(() => import("./pages/Skill/Skill_List"));
const Category_List = lazy(() => import("./pages/Category/Category_List"));
const Job = lazy(() => import("./pages/Job/Job"));
const Alert = lazy(() => import("./pages/Alert/Alert"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const Notificationp = lazy(() => import("./pages/Notification/Notification"));

const Version = lazy(() => import("./pages/Version/Version"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/users" component={User_List} />
        <ContentRoute path="/convertion" component={Convertion} />

        <ContentRoute path="/subadmin" component={Sub_Admin} />
        <ContentRoute path="/user_details" component={User_Details} />
        <ContentRoute path="/user_chat" component={User_Chat} />
        <ContentRoute path="/convertion_chat" component={Convertion_Chat} />



        <ContentRoute path="/photographers" component={Photo_Grapher} />
        <ContentRoute path="/onboarding" component={OnBoarding} />

        <ContentRoute
          path="/photoGrapher_details"
          component={PhotoGrapher_Details}
        />

        <ContentRoute path="/category" component={Category_List} />

        <ContentRoute path="/skill" component={Skill_List} />

        <ContentRoute path="/booking" component={Booking} />
        <ContentRoute path="/alert" component={Alert} />
        <ContentRoute path="/notification" component={Notification} />
        <ContentRoute path="/photographernotification" component={Notificationp} />

        <ContentRoute path="/version" component={Version} />
        <ContentRoute path="/job" component={Job} />
        <ContentRoute path="/support" component={Support} />
        <ContentRoute path="/withdrawal" component={Withdrawal} />
        <ContentRoute path="/reports" component={Report} />
        <ContentRoute path="/events" component={Event} />
        <ContentRoute path="/q&a" component={QandA} />
        <ContentRoute path="/statistics" component={Statistics} />
        <ContentRoute path="/coupon" component={Coupon} />


        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
