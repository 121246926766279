import React, { useEffect, useState } from "react";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  reftoken,
} from "../../../helpers/API/ApiData";
import { makeStyles } from "@material-ui/styles";
import { MdKeyboardBackspace } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { FiEdit2 } from "react-icons/fi";
import { AiTwotoneDelete } from "react-icons/ai";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../_metronic/_partials/controls";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
// Validation schema
const CustomerEditSchema = Yup.object().shape({
  category: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Category is required"),
});
const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    marginBottom: "50px",
    marginRight: "40px",
    marginLeft: "40px",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "10px",
  },
}));
const Category_Edit = ({
  rowID,
  setRowID,
  open,
  setOpen,
  fetchDatas,
  currentpage,
  pagesize,
  state,
  searching,
}) => {
  // console.log(props);
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [update, getUpdate] = useState(true);
  const [errors, setError] = useState({});
  const [images, setImage] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [button, setbutton] = useState(false);
  const [validated, setValidated] = useState(false);
  const [imgUrl, setImgUrl] = React.useState();
  const validateForm = () => {
    console.log("valid");
    let errors = {};
    let formIsValid = true;
    if (!data.code) {
      console.log("a");
      formIsValid = false;
      // document.getElementById("validID").style.border = "2px solid #F64E60";
      errors["code"] = "Please Enter Code";
    }
    if (!imgUrl) {
      console.log("a");
      formIsValid = false;
      // document.getElementById("validID").style.border = "2px solid #F64E60";
      errors["imgUrl"] = "Please Enter Image";
    }
    if (!data.discountInMax) {
      console.log("a");
      formIsValid = false;
      // document.getElementById("validID").style.border = "2px solid #F64E60";
      errors["discountInMax"] = "Please Enter MAX DISCOUNT";
    }
    if (rowID) {
      if (data.status!==0 && data.status!==1) {
        console.log(data)
        console.log("a");
        formIsValid = false;
        // document.getElementById("validID").style.border = "2px solid #F64E60";
        errors["status"] = "Please Select Status";
      }
      if (data.type!==0 && data.type!==1 && data.type!==2) {
        console.log("a");
        formIsValid = false;
        // document.getElementById("validID").style.border = "2px solid #F64E60";
        errors["type"] = "Please Select Type";
      }
    }else{
      if (!data.status) {
        console.log(data)
        console.log("a");
        formIsValid = false;
        // document.getElementById("validID").style.border = "2px solid #F64E60";
        errors["status"] = "Please Select Status";
      }
      if (!data.type) {
        console.log("a");
        formIsValid = false;
        // document.getElementById("validID").style.border = "2px solid #F64E60";
        errors["type"] = "Please Select Type";
      } 
    }
   
    setError(errors);
    return formIsValid;
  };
  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };
  // const handleChange = (e) => {
  //   let { name, value } = e.target;
  //   if (value.length > 0) {
  //     document.getElementById("validID").style.border = "2px solid #1BC5BD";
  //     // console.log("sssss");
  //   } else {
  //     document.getElementById("validID").style.border = "2px solid #F64E60";
  //     // console.log("rrr");
  //   }
  //   if (name == "image") {
  //     let file = e.target.files[0];
  //     let fileURL = URL.createObjectURL(file);
  //     file.fileURL = fileURL;
  //     setImage([file]);
  //   } else {
  //     setData((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };
  const fetchData = (v) => {
    ApiGet("/coupon/" + v)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        setImgUrl(res.data.data.image);
      })
      .catch(async (err) => {
        
          ErrorToast(err.message);
        
      });
  };
  const onUpdate = async () => {
    if (validateForm()) {
      enableLoading();
      setbutton(true);
      try {
        const body = {
          id: data?._id,
          code: data.code,
          image: imgUrl,
          discountInMax: parseInt(data.discountInMax),
          discountInPercentage: 0,
          status: parseInt(data.status),
          type: parseInt(data.type),
        };
        console.log(body);
        ApiPut("/coupon/update", body)
          .then((res) => {
            console.log("ressssssssssss", res);
            SuccessToast(res?.data?.message);
            fetchDatas(1, 10, "");
            disableLoading();
            setData({code:"",discountInMax:"",discountInPercentage:"",status:"",type:""})
            setImage("")
            setOpen(!open);
            setbutton(false);
            
          })
          .catch(async (err) => {
            console.log(err);
           
              ErrorToast(err.message);
              disableLoading();
              setbutton(false);
            
          });
      } catch (error) {}
    }
  };
  const imageChange = async (e) => {
    console.log(e);
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/coupon", formData)
      .then((res) =>
        setImgUrl(res?.data?.data?.image)
      )
      .catch((err) => console.log("res_blob", err));
    document.getElementById("inFile").value = "";
  };
  const handleSubmit = () => {
    if (validateForm()) {
      enableLoading();
      setbutton(true);
      try {
        const body = {
          code: data.code,
          image: imgUrl,
          discountInMax: parseInt(data.discountInMax),
          discountInPercentage: 0,
          status: parseInt(data.status),
          type: parseInt(data.type),

        };
        console.log(body);
        ApiPost("/coupon/add", body)
          .then((res) => {
            console.log("ressssssssssss", res);
            SuccessToast(res?.data?.message);
            fetchDatas(1, 10, "");
            disableLoading();
            setbutton(false);
            setData({code:"",discountInMax:"",discountInPercentage:"",status:"",type:""})
            setImage("")
            setOpen(!open);
            
          })
          .catch(async (err) => {
            
              ErrorToast(err.message);
              disableLoading();
              setbutton(false);
            
          });
      } catch (error) {}
    }
    // setValidated(true);
  };
  const onSubmit = async () => {
    // if (validateForm()) {
    //   } catch (err) {}
    // }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((preData) => ({
      ...preData,
      [name]: value,
    }));
  };
  console.log(data)
  useEffect(() => {
    if (rowID) {
      fetchData(rowID);
      getUpdate(false);
    }
  }, []);
  const clear = () => {
    setOpen(!open);
    setRowID("");
  };
  return (
    <>
      <Modal
        show={open}
        centered
        size="lg"
        onHide={() => clear()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {update === true ? "Add" : "Edit"} Coupon{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
         
            <div className="col-lg-12">
              <Form.Group md="6">
                <Form.Label>Coupon</Form.Label>
               
              </Form.Group>
            </div>
            <div class="card-body pt-9 pb-0 mx-0 px-0 ">
                <div className="mb-7 text-center d-flex flex-column align-center overflow-scroll">
                  <label className="position-relative  d-flex justify-content-center align-content-center">
               
                  
                            {imgUrl?<div
                              style={{
                                marginRight: "30px",
                                width: "135px",
                                height: "135px",
                                borderRadius: "12%",
                                background: "#f3f3f3",
                                position: "relative",
                              }}
                            >
                          
                              <img
                                src={imgUrl}
                                alt="imgUrl"
                                className="editImg"
                                width={"130px"}
                                height={"130px"}
                                style={{ borderRadius: "12%" }}
                              />
                              {/* <div>
                                <div
                                  className="cursor-pointer"
                                  style={{color:'#3699FF'}}
                                  // onClick={()=>{idx=index;console.log("iside", index)}}
                                >
                                  <label htmlFor="editFile" role="button">
                                    <FiEdit2
                                      style={{
                                        position: "absolute",
                                        left: "-25px",
                                        top: 0,
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        padding: "6px",
                                        background: "transparent",
                                      }}
                                    ></FiEdit2>
                                  </label>
                                </div>
                                <div
                                  className="cursor-pointer"
                                  style={{ color: "#3699FF" }}
                                  // onClick={() => handleDletImgage(imgUrl)}
                                >
                                  <AiTwotoneDelete
                                    style={{
                                      position: "absolute",
                                      top: "30px",
                                      left: "-25px",
                                      borderRadius: "50%",
                                      width: "30px",
                                      height: "30px",
                                      padding: "6px",
                                      background: "transparent",
                                    }}
                                  ></AiTwotoneDelete>
                                </div>
                              </div> */}
                            </div>
                          
                        
                    :
                        <div
                          style={{
                            marginLeft: "22px",
                            width: "130px",
                            height: "130px",
                            borderRadius: "12%",
                            background: "#f3f3f3",
                            position: "relative",
                          }}
                        ></div>}
                        <span style={{ color: "red", top: "5px", fontSize: "10px" }}>
                  {errors["imgUrl"]}
                </span>
                      
                   
                    <label
                      htmlFor="inFile"
                      className="shadow d-inline inFiles position-absolute   right-0"
                      role="button"
                    >
                      <div class="card-toolbar">
                        <a class="btn btn-primary font-weight-bolder">
                          Add Images
                        </a>
                      </div>
                    </label>
                  </label>
                  <input
                    type="file"
                    name="1"
                    id="inFile"
                    hidden
                    onChange={imageChange}
                  />
                  {/* <input
                    type="file"
                    name="2"
                    id="editFile"
                    hidden
                    // onChange={imageeditchage}
                  /> */}
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Code Name</label>
                  <div class="col-lg-8">
                    <input
                      type="text"
                      name="code"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.code}
                    />
                    <span style={{ color: "red", top: "5px", fontSize: "10px" }}>
                  {errors["code"]}
                </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">MAX DISCOUNT</label>
                  <div class="col-lg-8">
                    <input
                      type="number"
                      name="discountInMax"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.discountInMax}
                    />
                    <span style={{ color: "red", top: "5px", fontSize: "10px" }}>
                  {errors["discountInMax"]}
                </span>
                  </div>
                </div>
                {/* <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">DISCOUNT (%)</label>
                  <div class="col-lg-8">
                    <input
                      type="number"
                      name="discountInPercentage"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.discountInPercentage}
                    />
                  </div>
                </div> */}

                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Status</label>
                  <div class="col-lg-8">
                  <select
                          name="status"
                          id="kt_datatable_search_status"
                          className="form-control"
                          onChange={handleChange}
                          value={data?.status}
                        >
                          <option value="">Select Status</option>
                          {/* {category?.map((val) => ( */}
                          <option value={0}>InActive</option>
                            <option value={1}>Active</option>

                          {/* ))} */}
                        </select>
                        <span style={{ color: "red", top: "5px", fontSize: "10px" }}>
                  {errors["status"]}
                </span>
                  </div>
                </div>

                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Type</label>
                  <div class="col-lg-8">
                  <select
                          name="type"
                          id="kt_datatable_search_status"
                          className="form-control"
                          onChange={handleChange}
                          value={data?.type}

                        >
                          <option value="">Select Type</option>
                          {/* {category?.map((val) => ( */}
                          <option value={0}>Single Time</option>
                            <option value={1}>Multiple Time</option>
                            <option value={2}>Only For First User</option>

                          {/* ))} */}
                        </select>
                        <span style={{ color: "red", top: "5px", fontSize: "10px" }}>
                  {errors["type"]}
                </span>
                  </div>
                </div>
                

                {/* <div className="d-flex align-center justify-content-center">
                  <button
                    className="btn btn-primary float-center w-30"
                    onClick={addEvent}
                  >
                    Save Changes
                  </button>
                </div> */}
              </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={clear}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {update === true ? (
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary btn-elevate"
              disabled={button}
            >
              Submit
            </button>
          ) : (
            <button
              type="submit"
              onClick={onUpdate}
              disabled={button}
              className="btn btn-primary btn-elevate"
            >
              Update
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Category_Edit;
