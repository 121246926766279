import * as RiIcons from "react-icons/ri";

export const menu = [
  {
    title: "Dashboard",
    pathname: "dashboard",
    img: `/media/svg/icons/Design/Layers.svg`,
    flag: true,
  },
  {
    title: "Sub Admin",
    pathname: "subadmin",
    img: `/media/svg/icons/Home/Book.svg`,
    flag: true,
  },
  {
    title: "Users",
    pathname: "users",
    img: `/media/svg/icons/Home/Book.svg`,
    flag: true,
  },
  {
    title: "Photographers ",
    pathname: "photographers",
    img: `/media/svg/icons/General/User.svg`,
    flag: true,
  },
  
  {
    title: "Categories",
    pathname: "category",
    img: `/media/svg/icons/General/Star.svg`,
    flag: true,
  },
  {
    title: "Skills ",
    pathname: "skill",
    img: `/media/svg/icons/Shopping/Box2.svg`,
    flag: true,
  },
  {
    title: "Bookings ",
    pathname: "booking",
    img: `/media/svg/icons/General/Bookmark.svg`,
    flag: true,
  },
  {
    title: "Job",
    pathname: "job",
    img: `/media/svg/icons/Design/Cap-2.svg`,
    flag: true,
  },
  {
    title: "Coupon",
    pathname: "coupon",
    img: `/media/svg/icons/Design/Cap-2.svg`,
    flag: true,
  },
  {
    title: "Alert",
    pathname: "alert",
    img: `/media/svg/icons/Code/Error-circle.svg`,
    flag: true,
  },

  {
    title: "Notification",
    pathname: "notification",
    img: `/media/svg/icons/Code/Error-circle.svg`,
    flag: true,
  },

  {
    title: "Photographer Notification",
    pathname: "photographernotification",
    img: `/media/svg/icons/Code/Error-circle.svg`,
    flag: true,
  },
  {
    title: "Version",
    pathname: "version",
    img: `/media/svg/icons/Home/Book.svg`,
    flag: true,
  },
  {
    title: "On Boarding",
    pathname: "onboarding",
    img: `/media/svg/icons/General/User.svg`,
    flag: true,
  },
];
